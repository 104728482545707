import * as React from "react"

const Cta = ({content}) => {
  return (
  <div className='p30 fade--in' data-sal>
    <p className='text-center m0 h1 max-700 ma' dangerouslySetInnerHTML={{ __html: content }}/>
  </div>
  )
}
export default Cta
